window.addEventListener('DOMContentLoaded', () => {
  /* Toggling the header search input focus */

  const headerSearch = document.querySelector(
    '.js-utility-nav__link--search',
  ) as HTMLLIElement;
  const headerSearchInput = document.getElementById(
    'header-search-input',
  ) as HTMLInputElement;
  const autoSuggestList = document.querySelector(
    '.js-search__autosuggest',
  ) as HTMLDivElement;

  headerSearch.addEventListener('click', () => {
    if (window.getComputedStyle(headerSearchInput).visibility === 'visible') {
      headerSearchInput.focus();
    }
  });

  /* Fetching search results for auto suggestions */

  let timer: NodeJS.Timeout;

  const waitTime = 400;

  const searchURL = new URL(
    'https://www.googleapis.com/customsearch/v1/siterestrict',
  );
  searchURL.searchParams.set('key', 'AIzaSyBWP3164s22lu_t2U6527P1krnkt2g0Huk');
  searchURL.searchParams.set('cx', '012897451125057753504:rxeipfvkafe');

  let autoSuggestionResults: HTMLCollectionOf<Element>;

  interface IResult {
    link: string;
    snippet: string;
    title: string;
  }

  const GoogleSearch = async (query: string | null) => {
    if (!query) return;
    searchURL.searchParams.set('q', query);
    const response = await fetch(searchURL);

    return await response.json();
  };

  const fetchResults = async () => {
    const query = headerSearchInput.value;
    const results = await GoogleSearch(query);
    const maxResults = 10;
    autoSuggestionResults = document.getElementsByClassName(
      'autosuggest__result',
    );

    if (results.items) {
      for (const item of autoSuggestionResults) {
        item.remove();
      }
      autoSuggestList.classList.add('is-visible');

      results.items?.slice(0, maxResults).forEach((result: IResult) => {
        const div = document.createElement('div');
        div.className = 'autosuggest__result';

        const a = document.createElement('a');
        a.className = 'autosuggest__result-title-link';
        a.textContent = result.title;
        a.href = result.link;

        const span = document.createElement('span');
        span.className = 'autosuggest__result-url';
        span.textContent = result.link;

        const p = document.createElement('p');
        p.className = 'autosuggest__result-snippet';
        p.textContent = result.snippet;

        autoSuggestList.appendChild(div);
        div.appendChild(a);
        div.appendChild(span);
        div.appendChild(p);
      });
    } else {
      autoSuggestList.classList.remove('is-visible');
    }
  };

  headerSearchInput.addEventListener('input', () => {
    const value = headerSearchInput.value;

    clearTimeout(timer);

    if (value.trim() !== '') {
      timer = setTimeout(() => {
        fetchResults();
      }, waitTime);
    } else {
      autoSuggestList.classList.remove('is-visible');
    }
  });

  /* Hide search results list if reset button is clicked */

  const searchReset = document.querySelector('.search__reset');

  searchReset?.addEventListener('click', () => {
    if (autoSuggestionResults) {
      autoSuggestList.classList.remove('is-visible');
    }
  });
});
