import $ from 'jquery';

const _stripLocale = function (pathname: string) {
  let _pathname = pathname;
  if (_pathname.substring(0, 1) === '/') {
    _pathname = pathname.substr(1); // strip leading slash
  }
  const pathArr = _pathname.split('/');
  pathArr.shift(); // removes the locale
  return pathArr.join('/');
};

const $leftNav = $('.left-nav');

if ($leftNav.length > 0) {
  const pathname = _stripLocale(window.location.pathname);
  const leftNavLinks = $.map($leftNav.find('.left-nav__link'), (link) =>
    $(<string>link).attr('href'),
  );

  leftNavLinks.forEach((link) => {
    const linkIsActive =
      new RegExp(`^${link}/`).test(pathname) || link === pathname;

    if (linkIsActive) {
      $leftNav
        .find(`.js-left-nav__hasLink:has(a[href=${CSS.escape(link)}])`)
        .addClass('left-nav__link--active');
    }
  });
}
