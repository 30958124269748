import { Carousel } from 'bootstrap';

export const subnav = document.querySelector('.js-subnav');
const subnavLinks =
  document.querySelectorAll<HTMLLinkElement>('.js-subnav-link');
const subnavDropdownBtn = document.querySelector(
  '.js-subnav-dropdown-button',
) as HTMLButtonElement;

if (subnav) {
  subnavLinks.forEach((link: HTMLLinkElement) => {
    const linkUrl = new URL(link.href);
    if (window.location.pathname === linkUrl.pathname) {
      // Desktop view
      link.classList.add('active');
      link.setAttribute('aria-current', 'page');

      // Mobile view
      subnavDropdownBtn.innerHTML = link.innerText;
    }

    if (window.location.href.includes('connect-with-a-minfraud-expert')) {
      // Mobile view
      subnavDropdownBtn.innerHTML = 'Fraud prevention solutions';
    }

    if (window.location.href.includes('connect-with-a-geoip-expert')) {
      // Mobile view
      subnavDropdownBtn.innerHTML = 'GeoIP solutions';
    }
  });
}

const buyNowBtn = document.querySelector(
  '.js-buy-now-btn',
) as HTMLButtonElement;
const pricingSection = document.querySelector('.js-plans-and-pricing-section');

if (buyNowBtn && pricingSection) {
  buyNowBtn.addEventListener('click', () => {
    pricingSection.scrollIntoView({ behavior: 'smooth' });
    window.location.hash = 'buy-now';
  });

  // Go directly to the Pricing tab
  if (window.location.hash === '#buy-now') {
    const carouselDiv = document.querySelector('#geoip-databases-carousel');
    if (carouselDiv) {
      const carousel = Carousel.getOrCreateInstance(carouselDiv);
      carousel.to(2);
    } else {
      console.error('Cannot find #geoip-databases-carousel');
    }
  }
}
