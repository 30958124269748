import $ from 'jquery';

$('#sidebar-toggle-switch').on('click', () => {
  const toggleText =
    $('#sidebar-container').css('display') === 'none' ? 'Hide' : 'Show';

  $('#sidebar-container').toggle();
  $('#sidebar-chevron').toggleClass('bi bi-chevron-right bi bi-chevron-down');
  $('#content-container').toggleClass('col-md-9');
  $('.toggle-header').toggleClass('offset-md-3');
  $('#sidebar-action').html(toggleText);
});

$('#sidebar-toggle-switch').trigger('click');

$('.dropdown-menu form').on('click', (e) => {
  e.stopPropagation();
});

const _clearEmptyError = function ($target: JQuery) {
  $target.on('input change focusin', () => {
    if ($target.val() && String($target.val()).trim() === '') {
      return $target.addClass('is-empty');
    }
    return $target.removeClass('is-empty');
  });
};

const $requiredTextInput = $(
  [
    'input[type="text"][required]',
    'input[type="number"][required]',
    'input[type="email"][required]',
    'textarea[required]',
  ].join(','),
);

if ($requiredTextInput.length) {
  $requiredTextInput.each(function () {
    _clearEmptyError($(this));
  });
}
